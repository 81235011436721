<template>
    <BT-Blade
        v-if="locationID != null"
        :canClose="false"
        :canRefresh="false"
        flexColumn
        fixedWidth="100%"
        hideBladeHeader
        title="Production">
        <template>
            <BT-Blade-Items
                @select="selectBatch"
                @selectMeasurements="mList => measurements = mList"
                canBreakdown
                :canClose="false"
                canExportCSV
                :canMinimize="false"
                :canPin="false"
                :canSelect="false"
                canSearchLocal
                canUnselect
                :customFilters="customFilters"
                :defaultFilters="defaultFilters"
                :dense="false"
                flexColumn
                :getSelectBladeData="(bladeData, item, mList) => { return { batch: item, measurements: mList, autoSave: autoSave } }"
                :headers="headers"
                hideActions
                :loadingMsg="loadingMsg"
                measurementType="Production"
                navigation="batches"
                :onCanPull="() => { return locationID != null }"
                :onFilter="filterBatches"
                :onPullSuccessAsync="pullBatches"
                :params="{ locationID: locationID }"
                :refreshToggle="refreshToggle"
                :resetToggle="resetToggle"
                :searchProps="['product.abbreviation']"
                :small="false"
                :actualUsedHeight="152"
                useServerPagination>
                <template v-slot:toolbar>
                    <BT-Entity
                        :itemValue="locationID"
                        navigation="locations">
                        <template v-slot="{ item }">
                            <v-toolbar-title>Production Orders: {{ item.locationName }}</v-toolbar-title>
                        </template>
                    </BT-Entity>
                </template>
                <template v-slot:actions>
                    <v-btn large @click.stop="selectDOM" class="mx-2 primary">
                        <span v-if="domFilter != null">DOM: {{ domFilter | toDayShortDate }}</span>
                        <span v-else>Find By DOM</span>
                    </v-btn>
                    <v-btn large @click.stop="selectEXP" class="mx-2 primary">
                        <span v-if="expFilter != null">EXP: {{ expFilter | toDayShortDate }}</span>
                        <span v-else>Find By EXP</span>
                    </v-btn>
                </template>
                <template v-slot:actionsRight>
                    <v-btn icon @click="showWarningBatches">
                        <BT-Badge
                            color="warning"
                            icon="mdi-alert-outline"
                            navigation="batches"
                            :params="badgeParams" />
                    </v-btn>
                </template>
                <template v-slot:toolbar-right>
                    <v-btn icon @click="openNewBatchBlade">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <template v-slot:settings="{ items, measurements }">
                    <v-list-item @click="toggleArchives">
                        <v-list-item-icon>
                            <v-icon :class="showArchives ? 'success--text' : ''">mdi-archive</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-subtitle>Show Archives</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="showBatchcode = !showBatchcode">
                        <v-list-item-icon>
                            <v-icon :class="showBatchcode ? 'success--text' : ''">mdi-code-equal</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-subtitle>Show Batchcodes</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="showTotals = !showTotals">
                        <v-list-item-icon>
                            <v-icon :class="showTotals ? 'success--text' : ''">mdi-sigma</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-subtitle>Show Totals</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    
                    <v-divider class="my-2" />

                    <v-list-item @click="showPrintToggle = !showPrintToggle">
                        <v-list-item-icon><v-icon>mdi-printer</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Print Production Sheet</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    
                    <BT-Print
                        :buttonClass="null"
                        defaultStrategy="jspdf"
                        :getFileName="() => 'Production Orders.pdf'"
                        hide
                        landscape
                        :showToggle="showPrintToggle"
                        :small="false"
                        text="Print"
                        title="Print"
                        :useHTML2PDF="false"
                        width="100%">
                        <template>
                            <v-container class="pdf-page-landscape" fluid>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <h4 style="border-bottom: 1px solid lightgray; margin-bottom: 5px;">Production Orders</h4>
                                    </v-col>
                                    <v-col cols="12">
                                        <BT-Table
                                            :canRefresh="false"
                                            :headers="[
                                                { text: 'DOM', value: 'dom', textFilter: 'toShortDate' },
                                                { text: 'EXP', value: 'exp', textFilter: 'toShortDate' },
                                                { text: 'Product', value: 'product.productName' },
                                                { text: 'Goal', value: 'quantity', textFilter: 'toDisplayNumber', display: true },
                                                { text: 'Goal Breakdown', value: 'quantityGoal', breakdown: true }]"
                                            hideActions
                                            :items="items"
                                            :measurements="measurements">
                                            <template v-slot:quantity="{ item }">
                                                {{ item.quantityGoal | toDisplayNumber }}
                                            </template>
                                        </BT-Table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                    </BT-Print>
                </template>
                <template v-slot:select="{ item }">
                    <v-btn icon @click.stop="item.showTotals = !item.showTotals">
                        <v-icon :class="item.showTotals ? 'success--text' : ''">mdi-sigma</v-icon>
                    </v-btn>
                </template>
                <template slot="quantityGoal" slot-scope="{ item, measurements }">
                    <BT-Increment-Quantity
                        v-model="item.quantityGoal"
                        inline
                        :measurements="measurements"
                        :productID="item.productID" />
                </template>
                <template v-slot:quantity="{ item, measurements }">
                    <BT-Menu
                        :getOnOpenAsync="getUserActivities"
                        hideHeader
                        icon="mdi-history"
                        ignoreID
                        :item="item"
                        left
                        navigation="stock-user-activities"
                        small>
                        <template v-slot="{ item }">
                            <v-list-item v-for="(activity, ind) in item" :key="ind" dense>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <BT-Entity
                                            navigation="users"
                                            :itemValue="activity.userID"
                                            itemText="userName"
                                            inline>
                                            <template v-slot:append><span>{{ activity.notes }}</span></template>
                                            <template v-slot:alternate><span>{{ activity.notes }}</span></template>
                                        </BT-Entity>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-row class="ma-0 pa-0">
                                            <BT-Relative-Time v-model="activity.createdOn" />
                                            <v-spacer />
                                            <span>{{ activity.createdOn | toTime }}</span>
                                        </v-row>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </BT-Menu>
                    <span v-if="!item.isPending && item.quantity == 0">0</span>
                    <span v-else-if="item.isPending">...?</span>
                    <BT-Increment-Quantity
                        v-else
                        v-model="item.quantity"
                        inline
                        :measurements="measurements"
                        :productID="item.productID" />
                </template>
                <template slot="dom" slot-scope="{ item, data }">
                    <BT-Date-Picker 
                        v-model="item.dom"
                        dense
                        :disabled="!data.isEditing"
                        hide-details />
                </template>
                <template slot="exp" slot-scope="{ item, data }">
                    <BT-Date-Picker 
                        v-model="item.exp"
                        dense
                        :disabled="!data.isEditing"
                        hide-details />
                </template>
                <template v-slot:product="{ item }">
                    <span v-if="item.isArchive">(Archived) </span>
                    <span v-if="item.product != null">{{ item.product.productName }}</span>
                </template>
                <template v-slot:footer="{ measurements, items }">
                    <v-row v-if="showTotals" key="6" class="my-1">
                        <v-col cols="6">
                            <span class="overline mr-2 my-auto">Progress:</span>
                            <BT-Increments-Quantity-String
                                :abbreviation="false"
                                :measurements="measurements"
                                :items="items.filter(x => x.showTotals)"
                                spanClass="overline my-auto mr-2"
                                valueProp="quantity" />
                        </v-col>
                        <v-col cols="6">
                            <span class="overline mr-2 my-auto">Goal:</span>
                            <BT-Increments-Quantity-String
                                :abbreviation="false"
                                :measurements="measurements"
                                :items="items.filter(x => x.showTotals)"
                                spanClass="overline my-auto mr-2"
                                valueProp="quantityGoal" />
                        </v-col>
                    </v-row>
                </template>
            </BT-Blade-Items>
            <v-navigation-drawer
                app
                bottom
                clipped
                hide-overlay
                right
                style="height: 100%"
                v-model="progressDrawer"
                width="350">
                <v-toolbar class="primary" dark>
                    <v-btn icon large @click="selectBatch(null)">
                        <v-icon large>mdi-arrow-right</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ currentBatch != null ? currentBatch.product.abbreviation + ' Progress' : 'Batch' }}</v-toolbar-title>
                </v-toolbar>
                <v-container v-if="currentBatch != null" class="ma-0 pa-0">
                    <v-progress-linear
                        v-if="currentBatch != null"
                        color="primary"
                        height="48"
                        :value="Math.floor(((currentBatch.quantity / currentBatch.quantityGoal) * 10000) / 100)">
                        <template v-slot>
                            <BT-Increment-Quantity
                                v-if="currentBatch.quantityGoal > 0"
                                :value="currentBatch.quantity < currentBatch.quantityGoal ? currentBatch.quantity : currentBatch.quantityGoal"
                                class="ml-2"
                                inline
                                :measurements="measurements"
                                :productID="currentBatch.productID" />

                                <v-spacer />

                                <span v-if="currentBatch.quantity > 0 && currentBatch.quantityGoal > 0">{{ Math.floor(((currentBatch.quantity / currentBatch.quantityGoal) * 10000) / 100)}}%</span>

                                <BT-Increment-Quantity v-else
                                    :value="currentBatch.quantity"
                                    class="ml-2"
                                    inline
                                    :measurements="measurements"
                                    :productID="currentBatch.productID" />

                                <v-spacer />

                            <BT-Increment-Quantity
                                v-if="currentBatch.quantityGoal > 0"
                                class="mr-2"
                                :value="currentBatch.quantity > currentBatch.quantityGoal ? currentBatch.quantity - currentBatch.quantityGoal : currentBatch.quantityGoal - currentBatch.quantity"
                                inline
                                :measurements="measurements"
                                :prefix="currentBatch.quantity > currentBatch.quantityGoal ? '+' : ''"
                                :productID="currentBatch.productID" />

                        </template>
                    </v-progress-linear>

                    <v-card
                        v-for="(goalInc, index) in goalIncrements"
                        :key="index"
                        class="ma-0 pa-0">
                        <v-subheader>{{ goalInc.text }}</v-subheader>
                        <v-row dense>
                            <v-col cols="3">
                                <v-btn
                                    block
                                    class="ma-1 error"
                                    @click="removeOneQuantity(goalInc)"
                                    :disabled="currentBatch != null && currentBatch.isArchive"
                                    height="68">-1</v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn
                                    block
                                    class="ma-1 success"
                                    @click="updateOneQuantity(goalInc)"
                                    :disabled="currentBatch != null && currentBatch.isArchive"
                                    height="68">
                                    +1 {{ goalInc.text }}
                                </v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn
                                    block
                                    class="ma-1 primary"
                                    @click="updateSelectQuantity(goalInc)"
                                    :disabled="currentBatch != null && currentBatch.isArchive"
                                    height="68">
                                    + / -
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>

                    <v-divider class="my-2" />

                    <BT-Numpad
                        addOrRemove
                        @add="addToQuantity"
                        buttonHeight="50"
                        @remove="removeFromQuantity" />
                </v-container>

                <Batch-Blade v-else-if="batchBladeData != null" :customBladeData="batchBladeData" @saved="batchSaved"/>

                <v-overlay :value="loadingMsg != null" class="text-center">
                    <v-progress-circular indeterminate size="64" />
                    <div>{{ loadingMsg }}</div>
                </v-overlay>
            </v-navigation-drawer>
        </template>
    </BT-Blade>
</template>

<script>
import { firstBy } from 'thenby';
import { getBreakdown } from '~helpers';

export default {
    name: 'Production-Hub',
    components: {
        BTIncrementQuantity: () => import('~components/BT-Increment-Quantity.vue'),
        BTIncrementsQuantityString: () => import('~components/BT-Increments-Quantity-String.vue'),
        BTMenu: () => import('~components/BT-Menu.vue'),
        BTNumpad: () => import('~components/BT-Numpad.vue'),
        BTPrint: () => import('~components/BT-Print.vue'),
        BTTable: () => import('~components/BT-Table.vue'),
        BatchBlade: () => import('~home/batches/Batch-Blade.vue'),
        // ProductionOrderProgressBlade: () => import('~home/batches/Production-Order-Progress-Blade.vue'),
    },
    data: function() {
        return {
            batchBladeData: null,
            currentBatch: null,
            defaultFilters: ['Today'],
            domFilter: null,
            expFilter: null,
            increments: null,
            loadingMsg: null,
            locationID: null,
            measurements: null,
            progressDrawer: false,
            refreshToggle: false,
            resetToggle: false,
            showArchives: false,
            showBatchcode: false,
            showPrintToggle: false,
            showTotals: false,
        }
    },
    async mounted() {
        if (this.locationID == null) {
            this.locationID = this.selectedLocationID();

            if (this.locationID == null) {
                this.locationID = await this.$selectItem({
                    navigation: 'locations', 
                    itemText: 'locationName', 
                    itemValue: 'id',
                    required: true });
            }
        }
    },
    computed: {
        customFilters() {
            var l = [];
            l.push({ filter: 'Selected DOM', filterFunction: () => `DOM > ${this.$BlitzIt.auth.formRawTZ(this.domFilter).startOf('day').plus({ minutes: -1 }).toUTC()} ANDALSO DOM < ${this.$BlitzIt.auth.formRawTZ(this.domFilter).startOf('day').plus({ days: 1 }).toUTC()}` }); // ANDALSO DOM < ${dTo}`});
            l.push({ filter: 'Selected EXP', filterFunction: () => `EXP > ${this.$BlitzIt.auth.formRawTZ(this.expFilter).startOf('day').plus({ minutes: -1 }).toUTC()} ANDALSO EXP < ${this.$BlitzIt.auth.formRawTZ(this.expFilter).startOf('day').plus({ days: 1 }).toUTC()}` }); // ANDALSO DOM < ${dTo}`});            
            l.push({ filter: 'Past And Pending', filterFunction: () => `DOM < ${this.getToday()} ANDALSO IsPending IS true`});
            l.push({ filter: 'Today', filterFunction: () => `DOM > ${this.getBeforeToday()} ANDALSO DOM < ${this.getTomorrow()}` });
            l.push({ filter: 'Tomorrow', filterFunction: () => `DOM > ${this.getTomorrow()} ANDALSO DOM < ${this.getDayAfterTomorrow()}`});
            l.push({ filter: 'All Future', filterFunction: () => `DOM > ${this.getTomorrow()}`});
            return l;
        },
        badgeParams() {
            return { query: `DOM < ${this.getToday()} ANDALSO IsPending IS true`, locationID: this.locationID };
        },
        goalIncrements() {
            if (this.currentBatch == null) {
                return [];
            }

            var l = getBreakdown(this.currentBatch.quantityGoal, this.measurements, this.increments, this.currentBatch.productID, false, false, false);

            if (this.isLengthyArray(l)) {
                return l.reverse();
            }

            return l;
        },
        headers() {
            var l = [];

            if (this.showTotals) {
                l.push({ text: '', value: 'select', display: true, width: 75 });
            }

            if (this.showBatchcode) {
                l.push({ text: 'Batchcode', value: 'batchcode', csv: true, searchable: true });
            }

            l.push({ text: 'DOM', value: 'dom', display: true, csvFilter: 'toShortDate' });
            l.push({ text: 'EXP', value: 'exp', display: true, csvFilter: 'toShortDate' });
            l.push({ text: 'Product', value: 'product', display: true, csv: true, searchable: true });
            l.push({ text: 'Goal', value: 'quantityGoal', display: true, csvBreakdown: true });
            l.push({ text: 'Made', value: 'quantity', display: true, csvBreakdown: true });

            return l;
        }
    },
    methods: {
        async getUserActivities(item) {
            console.log(item);
            if (item != null) {
                return await this.$BlitzIt.store.getAll('stock-user-activities', { id: item.id }, true, null, null);
            }
            else {
                return [];
            }
        },
        openNewBatchBlade() {
            console.log('opening batch blade');
            this.currentBatch = null;
            this.batchBladeData = {
                data: {
                    id: 'new',
                    locationID: this.locationID
                }
            };

            this.progressDrawer = true;
            // this.bladesData.openBlade({
            //     bladeName: 'batch',
            //     data: { id: 'new', locationID: this.locationID }
            // })
        },
        batchSaved() {
            this.progressDrawer = false;
            this.refreshToggle = !this.refreshToggle;
        },
        filterBatches(list) {
            list.sort(firstBy(x => x.product.sortNumber).thenBy(x => x.dom));
            return list;
        },
        getBeforeToday() {
            return this.$BlitzIt.auth.createRawTZ().startOf('day').plus({ minutes: -1 }).toUTC().toString();
        },
        async pullBatches(list, refresh, bData, paramObj) {
            console.log('pulling batches');
            var archRes = null;
            
            if (this.showArchives) {
                archRes = await this.$BlitzIt.store.getAll('batch-archives', paramObj, refresh, null, null);
            }
            
            var rList = list.map(x => {
                return Object.assign({}, x, { originalQuantity: x.quantity, showTotals: true, isArchive: false });
            })

            if (this.isLengthyArray(archRes)) {
                var prodRes = await this.$BlitzIt.store.getAll('products');
                archRes.forEach(a => {
                    if (!rList.some(l => l.id == a.id)) {
                        a.product = prodRes.find(z => z.id == a.productID);
                        rList.push(Object.assign({}, a, { originalQuantity: a.quantity, showTotals: true, isArchive: true }));
                    }
                })
            }

            return rList;
        },
        async saveBatch(item) {
            try {
                item.loadingCount += 1
                this.$forceUpdate();

                if (item.quantity > 0) {
                    item.isPending = false;
                }

                var res = await this.$BlitzIt.store.patch('batches', item);
                item.errorMsg = null;
                item.rowVersion = res.rowVersion;
                item.originalQuantity = item.quantity;
            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingCount -= 1;
            }
        },
        async selectDOM() {
            this.domFilter = await this.$selectDate({
                label: 'Date Of Manufacture',
                fromNow: false,
                originalValue: this.domFilter
            });

            this.defaultFilters = ['Selected DOM'];

            this.resetToggle = !this.resetToggle;
        },
        async selectEXP() {
            this.expFilter = await this.$selectDate({
                label: 'Date Of Manufacture',
                fromNow: false,
                originalValue: this.expFilter
            });
            
            this.defaultFilters = ['Selected EXP'];

            this.resetToggle = !this.resetToggle;
        },
        async selectBatch(res) {
            console.log('selecting batch');
            if (this.increments == null) {
                try {
                    this.increments = await this.$BlitzIt.store.getAll('stock-increments');
                }
                catch (err) {
                    this.msg = this.extractErrorDescription(err);
                }
            }

            this.batchBladeData = null;
            this.currentBatch = res;
            this.progressDrawer = this.currentBatch != null;
        },
        showWarningBatches() {
            this.defaultFilters = ['Past And Pending'];
            this.resetToggle = !this.resetToggle;
        },
        toggleArchives() {
            this.showArchives = !this.showArchives;
            this.refreshToggle = !this.refreshToggle;
        },


        async addToQuantity(add) {
            if (!Number.isNaN(add)) {
                this.currentBatch.quantity += add;
                await this.updateQuantity();
            }
        },
        async removeFromQuantity(remove) {
            if (!Number.isNaN(remove)) {
                this.currentBatch.quantity -= remove;
                if (this.currentBatch.quantity < 0) {
                    this.currentBatch.quantity = 0;
                }

                await this.updateQuantity();
            }
        },
        async removeOneQuantity(goalInc) {
            var inc = goalInc.unitsPerPackage;
            
            if (inc <= 0) {
                return;
            }

            this.currentBatch.quantity -= inc;
            
            await this.updateQuantity();
        },
        async updateOneQuantity(goalInc) {
            var inc = goalInc.unitsPerPackage;
            
            if (inc <= 0) {
                return;
            }

            this.currentBatch.quantity += inc;
            
            await this.updateQuantity();
        },
        async updateSelectQuantity(goalInc) {
            var inc = goalInc.unitsPerPackage;
            
            if (inc <= 0) {
                return;
            }

            var multiple = await this.$selectQuantity({
                value: 1,
                label: 'Add/Remove ' + goalInc.text + 's'
            });

            if (multiple != 0) {
                this.currentBatch.quantity += (multiple * inc);
            }
            
            await this.updateQuantity();
        },
        async updateQuantity() {
            // if (this.quantityMade < 0) {
            //     this.quantityMade = 0;
            // }
            
            // this.currentBatch.quantity = this.quantityMade;
            if (this.currentBatch.quantity < 0) {
                this.currentBatch.quantity = 0;
            }

            try {
                this.loadingMsg = 'Saving';
                
                if (this.currentBatch.isPending && this.currentBatch.quantity > 0) {
                    this.currentBatch.isPending = false;
                }

                var res = await this.$BlitzIt.store.patch('batches', this.currentBatch);
                this.currentBatch.rowVersion = res.rowVersion;
                this.currentBatch.originalQuantity = res.quantity;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        }
    }
}
</script>